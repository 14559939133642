@if (!googleAuthService.loginLoading) {
  <article id="im-report-login" [ngClass]="{ mobile: genericService.isMobile() }">
    <section class="im-report-main">
      <img src="assets/images/main_left.png" alt="main image" />
      <div class="im-report-main-content">
        <div class="im-report-main-content-wrap">
          <h1 class="im-report-main-slogan">
            광고 데이터를 <br />
            구글 스프레드시트에 <br />
            자동 연동하기
          </h1>
          @if (!genericService.isMobile()) {
            <p class="im-report-main-summary">
              내가 원하는 데이터 리포트를 기존에 사용하던 보고서에 <br />
              연동하고 매일 아침 업데이트된 내역을 확인하세요.
            </p>
          }
        </div>
        <button (click)="signInWithGoogle()" class="im-report-main-login"><img src="assets/icon/google.png" alt="google icon" />구글로 로그인/회원가입</button>
        @if (genericService.isMobile()) {
          <p class="im-report-main-summary">
            데이터 자동 수집과 시각화를 통해 <br />
            놀라운 업무 효율 개선을 경험하세요
          </p>
        }
      </div>
    </section>
  </article>
  <app-landing-page (login)="signInWithGoogle()"></app-landing-page>
}
