import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { tap } from 'rxjs';

import { ConnectedManagerAccount, NaverAccount, Platform } from '../models/accounts.model';

import { ApiService } from '../../core/services/api.service';

import { ConnectedNaverDialogComponent } from '../components/dialogs/connected-naver-dialog/connected-naver-dialog.component';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { UserService } from '../../core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class NaverAccountService {
  AdvertisingAccounts: NaverAccount[] = [];
  selectAccount!: NaverAccount;
  connectedManagerAccount: ConnectedManagerAccount | null = null;
  naverSelectAccountKey: string = '_nsa';
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  getNaverAdvertisingAccounts() {
    const url = 'naver/accounts';
    return this.apiService.select(url).pipe(
      tap((response) => {
        this.AdvertisingAccounts = response.body;
        const checkSession = sessionStorage.getItem(this.naverSelectAccountKey);
        this.selectAccount = checkSession ? JSON.parse(checkSession) : this.AdvertisingAccounts![0];
      }),
    );
  }

  getConnectedManagerAccount() {
    this.userService.getConnectedManagerAccount(Platform.NAVER).subscribe((response) => {
      const body = response.body;
      if (body !== null) {
        this.connectedManagerAccount = body;
        this.getNaverAdvertisingAccounts();
      } else {
        this.connectedManagerAccount = this.initializeEmptyAccount();
      }
      this.openNaverConnectDialog();
    });
  }

  enrollNaverAccount(manageAccount: ConnectedManagerAccount) {
    const url = 'naver/enroll';
    const params = {
      access_license: manageAccount.access_license,
      naver_customer_id: manageAccount.naver_customer_id,
      secret_key: manageAccount.secret_key,
    };
    return this.apiService.create(url, params);
  }

  initializeEmptyAccount(): ConnectedManagerAccount {
    return {
      access_license: '',
      naver_customer_id: '',
      secret_key: '',
    };
  }

  openNaverConnectDialog() {
    const dialogRef = this.dialog.open(ConnectedNaverDialogComponent, { width: '640px ', data: this.connectedManagerAccount });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.userService.getUser();
      this.openConfirmDialog();
    });
  }

  openConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '400px' });
    const dialogInstance = dialogRef.componentInstance;
    dialogInstance.headerImage = 'assets/icon/check_circle.svg';
    dialogInstance.title = `네이버 검색 광고의
                            계정이 연결되었어요!`;
    dialogInstance.confirmText = '확인';
    dialogInstance.confirmButtonBgColor = `#837aff`;
    dialogInstance.onlyConfirm = true;
    dialogInstance.alignCenter = true;

    dialogRef.afterClosed().subscribe(() => {
      this.getNaverAdvertisingAccounts().subscribe(() => this.router.navigate([this.userService.user?.has_naver_raw_data ? '/my-data' : '/home']));
    });
  }
}
