import { Component, CUSTOM_ELEMENTS_SCHEMA, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';

import { delay, EMPTY, of, switchMap, take, takeWhile, timer } from 'rxjs';
import { register } from 'swiper/element/bundle';
// import { interval, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { fadeInOutTrigger } from '../../shared/animations';
import { TimeAgoPipe } from '../../shared/pipes/time-ago.pipe';

import { GoogleAuthService } from '../../core/services/google-auth.service';
import { NaverAccountService } from '../../shared/services/naver-account.service';
import { RawDataService } from '../../shared/services/raw-data.service';
import { UserService } from '../../core/services/user.service';
import { GenericService } from '../../shared/services/generic.service';

import { UpdateStatus, MyRawData, RawData, RawDataImportStatus, RawDataFormatType } from '../../shared/models/raw-data.model';

import { PlatformDropdownComponent } from '../../shared/components/platform-dropdown/platform-dropdown.component';
import { AccountDropdownComponent } from '../../shared/components/account-dropdown/account-dropdown.component';
import { CustomTooltipComponent } from '../../shared/components/custom-tooltip/custom-tooltip.component';
import { ReportDialogComponent } from '../../shared/components/dialogs/report-dialog/report-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-my-data',
  standalone: true,
  imports: [CommonModule, RouterModule, MatTooltipModule, TimeAgoPipe, PlatformDropdownComponent, AccountDropdownComponent, CustomTooltipComponent],
  templateUrl: './my-data.component.html',
  styleUrl: './my-data.component.less',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  animations: [fadeInOutTrigger('tooltip', '0.3s ease'), fadeInOutTrigger('buttons', '0.2s ease')],
})
export class MyDataComponent {
  // private pollingStopSubjects: { [key: number]: Subject<void> } = {};
  myRawData = signal<MyRawData[]>([]);
  statusTextSignals = signal<{ [key: number]: string }>({});
  activeRawData: RawDataFormatType[] = [];
  buttonHover: boolean = false;
  dataEmpty: boolean = false;
  importing: boolean = false;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private googleAuthService: GoogleAuthService,
    public naverAccountService: NaverAccountService,
    public rawDataService: RawDataService,
    public userService: UserService,
    public genericService: GenericService,
  ) {}

  ngOnInit(): void {
    this.googleAuthService.loginLoading = false;
    this.naverAccountService.getNaverAdvertisingAccounts().subscribe(() => this.getRawData());
  }

  getRawData() {
    this.rawDataService.getRawData().subscribe((response) => {
      this.myRawData.set(response.body);

      this.activeRawData = this.myRawData().map((item) => item.raw_data.type);
      this.convertingRawDataTitleAndThumbnails();
      this.rawDataService.loadTemplates().then(() => this.myRawData().forEach((item) => (item.raw_data._templates = this.rawDataService.templates[item.raw_data.type])));
      this.importing = false;
      this.myRawData().forEach((item) => {
        const trigger = item.importing_status > RawDataImportStatus.FAILED && item.importing_status < RawDataImportStatus.SUCCESS;
        if (!trigger) return;
        this.importing = true;
        register();

        // const addingTrigger = item.importing_status === RawDataImportStatus.ADDING;
      });
      this.myRawData().length === 0 && this.userService.getUser();
      this.myRawData().length ? (this.dataEmpty = false) : (this.dataEmpty = true);
    });
  }

  handleRawDataAction(action: string, rawData: MyRawData) {
    this.rawDataService.handleRawDataAction(action, rawData).subscribe((response) => {
      const trigger = response.body;
      if (!trigger) return;

      this.genericService.pushToDataLayer('dashboard_refresh_raw', {
        login_id: this.userService.user?.login_id,
        media_account_id: this.naverAccountService.selectAccount.naver_login_id,
        media: 'naver sa',
        raw_name: rawData.raw_data.type,
        page_name: this.genericService.currentPath,
      });

      this.getRawData();
    });
  }

  deletedRawData(rawDataId: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '460px' });
    const dialogInstance = dialogRef.componentInstance;

    dialogInstance.title = `연동 중인 데이터를 삭제하시겠어요?`;
    dialogInstance.description = `데이터 삭제 시, 스프레드시트에 연동된 데이터가
                                  더 이상 업데이트되지 않아요.`;
    dialogInstance.warningText = '사용 중인 스프레드시트는 삭제되지 않으니 걱정마세요.';
    dialogInstance.cancelText = '취소';
    dialogInstance.confirmText = '삭제';

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.rawDataService.deletedRawData(rawDataId).subscribe((response) => {
        const trigger = response.body;
        trigger && this.getRawData();
      });
    });
  }

  // startPolling, stopPolling, updateStatusText 기능 미사용 처리
  // 추후 재사용 가능성 있어 주석 처리함

  // startPolling() {
  //   this.myRawData().forEach((data) => {
  //     const trigger = data.importing_status > RawDataImportStatus.CREATING && data.importing_status < RawDataImportStatus.SUCCESS;
  //     if (!trigger) return;
  //     if (!this.pollingStopSubjects[data.raw_data.id]) this.pollingStopSubjects[data.raw_data.id] = new Subject<void>();
  //     register();
  //     interval(10000)
  //       .pipe(
  //         switchMap(() => this.rawDataService.checkRawDataLogStatus(data.raw_data.id)),
  //         takeUntil(this.pollingStopSubjects[data.raw_data.id]),
  //         tap((response: any) => {
  //           const status = response.body.status;
  //           data.importing_status = status;
  //           this.updateStatusText();
  //           if (status === RawDataImportStatus.SUCCESS || status === RawDataImportStatus.FAILED) {
  //             this.pollingStopSubjects[data.raw_data.id].next(); // Stop polling for this data
  //             this.pollingStopSubjects[data.raw_data.id].complete();
  //             delete this.pollingStopSubjects[data.raw_data.id]; // Clean up
  //             this.getRawData();
  //           }
  //         }),
  //       )
  //       .subscribe();
  //   });
  // }

  // stopPolling() {
  //   for (const key in this.pollingStopSubjects) {
  //     if (this.pollingStopSubjects.hasOwnProperty(key)) {
  //       this.pollingStopSubjects[key].next();
  //       this.pollingStopSubjects[key].complete();
  //       delete this.pollingStopSubjects[key]; // Clean up
  //     }
  //   }
  // }

  // updateStatusText() {
  //   const statuses = this.myRawData().map((item) => ({
  //     id: item.raw_data.id,
  //     status: item.importing_status,
  //   }));

  //   const statusTextMap: { [key in RawDataImportStatus]?: string } = {
  //     [RawDataImportStatus.CREATING]: '스프레드시트 생성 중 . .',
  //     [RawDataImportStatus.IMPORTING]: '플랫폼에서 데이터 수집 중 . .',
  //     [RawDataImportStatus.INSERTING]: '스프레드시트에 데이터 삽입 중 . .',
  //     [RawDataImportStatus.ADDING]: '템플릿 추가 중 . .',
  //   };

  //   const statusTexts: { [key: number]: string } = {};
  //   statuses.forEach(({ id, status }) => {
  //     const statusText = status !== null && status >= RawDataImportStatus.CREATING ? statusTextMap[status] || '' : '';
  //     statusTexts[id] = statusText;
  //   });

  //   this.statusTextSignals.set(statusTexts);
  // }

  // getStatusText(id: number): string {
  //   const signals = this.statusTextSignals();
  //   return signals[id] || '';
  // }

  convertingRawDataTitleAndThumbnails() {
    this.myRawData().forEach((item) => {
      item.raw_data._title = this.rawDataService.getDescriptionOrThumbnail(item.raw_data.type, 'title');
      item.raw_data._thumbnail = this.rawDataService.getDescriptionOrThumbnail(item.raw_data.type, 'thumbnail');
    });
  }

  routerSheet(rawData: RawData) {
    window.open(`https://docs.google.com/spreadsheets/d/${rawData.spreadsheet_id}/edit?gid=${rawData.sheetfile_id}#gid=${rawData.sheetfile_id}`);
  }

  openDialogReport(rawData: RawData) {
    const dialogRef = this.dialog.open(ReportDialogComponent, {
      minWidth: '800px',
      maxWidth: '800px',
      data: { rawData: rawData, type: 'add', myData: true, useTemplates: rawData.use_templates },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.rawDataService.addRawDataTemplate(rawData.id, result.templates).subscribe(async (response) => {
        const trigger = response.body;
        if (!trigger) return;

        result.templates.forEach((item: any) => {
          this.genericService.pushToDataLayer('dashboard_insert_report', {
            login_id: this.userService.user?.login_id,
            media_account_id: this.naverAccountService.selectAccount.naver_login_id,
            media: 'naver sa',
            report_name: this.rawDataService.templates[rawData.type].find((t: any) => t.report_no === item)?.sheetfile_name,
            page_name: this.genericService.currentPath,
          });
        });

        this.getRawData();
        const delayTime = 60000 * result.templates.length;
        this.addReportPolling(delayTime);
      });
    });
  }

  addReportPolling(delayTime: number) {
    of(null)
      .pipe(
        delay(delayTime),
        switchMap(() => {
          this.getRawData();
          return timer(30000, 30000).pipe(
            switchMap(() => {
              const hasAdding = this.myRawData().some((item) => item.importing_status === RawDataImportStatus.ADDING);
              if (!hasAdding) return EMPTY;
              this.getRawData();
              return of(null);
            }),
            takeWhile(() => this.myRawData().some((item) => item.importing_status === RawDataImportStatus.ADDING)),
            take(3),
          );
        }),
      )
      .subscribe();
  }

  onClickAddData() {
    this.router.navigate(['/add-data'], { state: { activeRawData: this.activeRawData } });
  }

  get RawDataImportStatus(): typeof RawDataImportStatus {
    return RawDataImportStatus;
  }

  get UpdateStatus(): typeof UpdateStatus {
    return UpdateStatus;
  }
}
