/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable, catchError, map, throwError, OperatorFunction } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = `https://${environment.baseURL}`;
  accessTokenKey: string = '_ac';
  refreshTokenKey: string = '_rc';
  serviceIdKey: string = '_si';
  constructor(private http: HttpClient) {}

  create(url: string, payload: {} = {}, params: {} = {}, headers: {} = {}): Observable<HttpResponse<any>> {
    const options = this.makeOptions(headers, params, { observe: 'response' });
    return this.http.post<any>(this.makeUrl(url), payload, options).pipe(mapToHttpResponse(), catchError(this.handleError.bind(this)));
  }

  select(url: string, params: {} = {}, headers: {} = {}): Observable<any> {
    const options = this.makeOptions(headers, params);
    return this.http.get<any>(this.makeUrl(url), options).pipe(catchError(this.handleError));
  }

  update(url: string, payload: {} = {}, params: {} = {}, headers: {} = {}): Observable<HttpResponse<any>> {
    const options = this.makeOptions(headers, params);
    return this.http.put<any>(this.makeUrl(url), payload, options).pipe(mapToHttpResponse(), catchError(this.handleError));
  }

  patch(url: string, payload: {} = {}, params: {} = {}, headers: {} = {}): Observable<HttpResponse<any>> {
    const options = this.makeOptions(headers, params);
    return this.http.patch<any>(this.makeUrl(url), payload, options).pipe(mapToHttpResponse(), catchError(this.handleError));
  }

  delete(url: string, params: {} = {}, headers: {} = {}): Observable<HttpResponse<any>> {
    const options = this.makeOptions(headers, params);
    return this.http.delete<any>(this.makeUrl(url), options).pipe(mapToHttpResponse(), catchError(this.handleError));
  }

  private getAccessToken(): string {
    return sessionStorage.getItem(this.accessTokenKey)!;
  }

  private getServiceId(): string {
    return localStorage.getItem(this.serviceIdKey)!;
  }

  private makeOptions(
    headers: {} = {},
    params: {} = {},
    etc: {
      observe?: 'events' | 'response' | 'body';
      contentType?: 'application/json' | 'multipart/form-data' | 'image/jpeg' | 'text/html';
      responseType?: 'json' | 'text' | 'blob';
    } = {
      observe: 'response',
      contentType: 'application/json',
      responseType: 'json' as const,
    },
  ): any {
    const httpHeaders = this.requestHeaders(headers, etc.contentType);
    const httpParams = new HttpParams({ fromObject: params as any });
    return {
      headers: httpHeaders,
      params: httpParams,
      reportProgress: true,
      withCredentials: true,
      responseType: etc.responseType,
      observe: etc.observe,
    };
  }

  private requestHeaders(headers: {}, contentType: string = 'application/json'): HttpHeaders {
    let httpHeaders = new HttpHeaders(headers);
    if (contentType) httpHeaders = httpHeaders.set('Content-Type', contentType);

    const accessToken = this.getAccessToken();
    if (accessToken) httpHeaders = httpHeaders.set('access-token', accessToken);

    const serviceId = this.getServiceId();
    if (serviceId) httpHeaders = httpHeaders.set('service-id', serviceId);

    return httpHeaders;
  }

  private makeUrl(url: string): string {
    return `${this.baseUrl}/${url}`;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    // 클라이언트나 네트워크 문제로 발생한 에러.
    if (error.status === 0) console.error('An error occurred:', error.error ? error.error.message : error.message);
    // 백엔드에서 실패한 것으로 보낸 에러.
    else console.error(`Backend returned code ${error.status}, body was: `, error.error);

    // 사용자 친화적인 메시지 반환
    const errorMsg = error.error?.message || error.message || 'An unknown error occurred';
    return throwError(() => new Error(errorMsg));
  }
}

function mapToHttpResponse(): OperatorFunction<HttpEvent<any>, HttpResponse<any>> {
  return map((event: HttpEvent<any>) => {
    if (event instanceof HttpResponse) {
      return event as HttpResponse<any>;
    }
    throw new Error('Unexpected event');
  });
}
