import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';

import { _mockRawData } from '../../../models/raw-data.model';

import { RawDataService } from '../../../services/raw-data.service';
import { fadeInOutTrigger } from '../../../animations';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-start-google-drive-dialog',
  standalone: true,
  imports: [CommonModule, MatMenuModule],
  templateUrl: './start-google-drive-dialog.component.html',
  styleUrl: './start-google-drive-dialog.component.less',
  animations: [fadeInOutTrigger('header', '0.2s ease'), fadeInOutTrigger('content', '0.3s ease')],
})
export class StartGoogleDriveDialogComponent {
  conversionEntries: { key: string; value: string }[] = [];
  selectedConversion: { key: string; value: string } = { key: '', value: '' };
  constructor(
    public rawDataService: RawDataService,
    private dialogRef: MatDialogRef<StartGoogleDriveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: _mockRawData,
  ) {
    this.rawDataService.selectedRawData = this.data;
    this.conversionEntries = Object.entries(this.rawDataService.conversionType).map(([key, value]) => ({ key, value }));
  }

  onClickButton(type: 'save' | 'sheets' | 'directory') {
    const result = {
      type: type,
      conversionType: this.selectedConversion.key,
    };
    this.dialogRef.close(result);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
