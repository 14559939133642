import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  template: `
    <article id="confirmation-dialog" [ngClass]="{ center: alignCenter }">
      @if (headerImage) {
        <img src="{{ headerImage }}" class="confirmation-dialog-header-image" />
      }
      @if (title) {
        <h2 class="confirmation-dialog-title">{{ title }}</h2>
      }
      @if (description) {
        <p class="confirmation-dialog-description" [ngStyle]="{ 'padding-bottom': warningText ? '0px' : '16px' }">{{ description }}</p>
      }
      @if (email) {
        <div class="confirmation-dialog-email">
          <div class="confirmation-dialog-email-wrap">
            <img src="assets/icon/gmail.png" alt="Google mail icon" />
            <span>{{ email }}</span>
          </div>
          <p>연동이 완료되면 로그인한 이메일로 안내해 드릴게요.</p>
        </div>
      }
      @if (warningText) {
        <ul class="confirmation-dialog-warning">
          <li>{{ warningText }}</li>
        </ul>
      }
      <div class="confirmation-dialog-buttons" [ngStyle]="{ width: onlyConfirm ? '100%' : '' }">
        @if (!onlyConfirm) {
          <button (click)="onCancel()" class="confirmation-dialog-cancel">{{ cancelText }}</button>
        }
        <button (click)="onConfirm()" [ngStyle]="onlyConfirm ? { 'background-color': confirmButtonBgColor, width: '100%' } : {}" class="confirmation-dialog-confirm">
          {{ confirmText }}
        </button>
      </div>
    </article>
  `,
  styles: `
    @import '../../../../app.component.less';
    #confirmation-dialog {
      padding: 32px 30px;
      &.center {
        align-items: center;
        text-align: center;
      }
      .flex-column (16px);
      .confirmation-dialog {
        &-title {
          .head-typo (2rem, 1.3);
          white-space: pre-line;
        }
        &-description {
          .body-typo (1.6rem, 1.5);
          color: @text-sub;
        }
        &-warning {
          padding-left: 20px;
          color: @warning;
          .head-typo (1.6rem);
        }
        &-buttons {
          .flex-row (flex-end, 8px);
          font-weight: 700;
        }
        &-cancel {
          .button (@width: 100px, @bg-color: @white, @border: 1px solid @gray300);
        }
        &-confirm {
          .button (@width: 100px, @bg-color: @gray950, @color: @white);
        }
        &-email {
          .flex-column (11px);
          &-wrap {
            padding: 16px 24px;
            border: 1px solid @gray300;
            .radius (20px);
            .body-typo (1.8rem, 1.5);
            .flex-row (@gap: 15px);
            justify-content: flex-start;
          }
          p {
         color: @gray900;
          }
          & + .confirmation-dialog-buttons .confirmation-dialog-confirm {
            width: 100px !important;
          }
        }
      }
    }
  `,
})
export class ConfirmationDialogComponent {
  @Input() title?: string;
  @Input() description: string = '';
  @Input() confirmText: string = '';
  @Input() cancelText: string = '';
  @Input() confirmButtonBgColor: string = ' ';
  @Input() onlyConfirm: boolean = false;
  @Input() headerImage: string = '';
  @Input() alignCenter: boolean = false;
  @Input() warningText: string = '';
  @Input() email: string = '';
  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

  onConfirm() {
    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
