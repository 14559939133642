import { CommonModule } from '@angular/common';
import { Component, ElementRef, Renderer2, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';

import { GenericService } from '../../shared/services/generic.service';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.less'],
})
export class LandingPageComponent implements AfterViewInit, OnDestroy {
  @ViewChild('parallaxContainer') parallaxContainer!: ElementRef;
  @ViewChild('parallaxElement') parallaxElement!: ElementRef;
  @Output() login = new EventEmitter();
  private scrollListener: (() => void) | null = null;
  private observer: IntersectionObserver | null = null;
  private initialScrollY: number | null = null;

  move: number = 0;
  play: boolean = true;

  marqueeContents = [
    { src: 'assets/images/landing/marquee1.png', art: 'go to work', text: '출근하고' },
    { src: 'assets/images/landing/marquee2.png', art: 'login', text: '로그인하고' },
    { src: 'assets/images/landing/marquee3.png', art: 'searching', text: '일일이 찾고' },
    { src: 'assets/images/landing/marquee4.png', art: 'copy and paste', text: '복붙하고' },
    { src: 'assets/images/landing/marquee5.png', art: 'tidy up', text: '정리하고' },
    { src: 'assets/images/landing/marquee6.png', art: 'Make a report', text: '보고하고' },
  ];

  reports = [
    {
      name: '파워링크 캠페인별 키워드 그래프',
      src: 'https://docs.google.com/spreadsheets/d/1ogRL1nwLF9LKK9CemGjuHbM6jA6ZM-Lxer_O4rYiQj0/edit?gid=2142266417#gid=2142266417&range=A1',
    },
    {
      name: '파워링크 캠페인별 키워드',
      src: 'https://docs.google.com/spreadsheets/d/1ogRL1nwLF9LKK9CemGjuHbM6jA6ZM-Lxer_O4rYiQj0/edit?gid=1378420137#gid=1378420137&range=A1',
    },
    {
      name: '파워링크 키워드 성과흐름',
      src: 'https://docs.google.com/spreadsheets/d/1ogRL1nwLF9LKK9CemGjuHbM6jA6ZM-Lxer_O4rYiQj0/edit?gid=2139735108#gid=2139735108&range=A1',
    },
    {
      name: '항목별 그룹유형 그래프',
      src: 'https://docs.google.com/spreadsheets/d/1ogRL1nwLF9LKK9CemGjuHbM6jA6ZM-Lxer_O4rYiQj0/edit?gid=127626964#gid=127626964&range=A1',
    },
    {
      name: '항목별 그룹유형',
      src: 'https://docs.google.com/spreadsheets/d/1ogRL1nwLF9LKK9CemGjuHbM6jA6ZM-Lxer_O4rYiQj0/edit?gid=961725091#gid=961725091&range=A1',
    },
    {
      name: '파워링크 캠페인별 세부 성과 흐름',
      src: 'https://docs.google.com/spreadsheets/d/1ogRL1nwLF9LKK9CemGjuHbM6jA6ZM-Lxer_O4rYiQj0/edit?gid=1769147604#gid=1769147604&range=A1',
    },
  ];

  constructor(
    public genericService: GenericService,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.initializeObserver();
  }

  ngOnDestroy(): void {
    this.removeEventListeners();
  }

  private initializeObserver(): void {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) this.startListeningToScroll();
        else this.stopListeningToScroll();
      });
    });

    this.observer.observe(this.parallaxContainer.nativeElement);
  }

  private startListeningToScroll(): void {
    if (this.initialScrollY === null) this.initialScrollY = window.scrollY;
    this.scrollListener = this.renderer.listen('window', 'scroll', this.onScroll.bind(this));
  }

  private stopListeningToScroll(): void {
    this.move = 0;
    if (this.scrollListener) {
      this.scrollListener();
    }
  }

  private removeEventListeners(): void {
    if (this.scrollListener) this.scrollListener();

    if (this.observer) this.observer.disconnect();
  }

  private onScroll(): void {
    if (this.initialScrollY === null) return;
    const scrollY = window.scrollY;
    this.move = (scrollY - this.initialScrollY) * 0.1;
    this.renderer.setStyle(this.parallaxElement.nativeElement, 'backgroundPositionY', `-${this.move}px`);
  }

  onClickPreview(link: string): void {
    window.open(link);
  }

  signIn() {
    this.login.emit();
  }
}
