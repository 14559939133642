/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { lastValueFrom } from 'rxjs';

import { TemplateData } from '../models/raw-data.model';

import { ApiService } from '../../core/services/api.service';

interface MyDB extends DBSchema {
  versionInfo: {
    key: string;
    value: { version: string; data: TemplateData };
  };
}

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  private dbName = 'templatesByRawData';
  private dbPromise!: Promise<IDBPDatabase<MyDB>>;

  constructor(private apiService: ApiService) {
    this.dbPromise = this.initDB();
  }

  // 인덱스드 디비 초기화
  private async initDB(): Promise<IDBPDatabase<MyDB>> {
    return openDB<MyDB>(this.dbName, 1, {
      upgrade(db) {
        !db.objectStoreNames.contains('versionInfo') && db.createObjectStore('versionInfo', { keyPath: 'version' });
      },
    });
  }

  // 현재 버전 가져오기
  getVersion(): Promise<any> {
    const url = 'template/version';
    return lastValueFrom(this.apiService.select(url));
  }

  // 템플릿 데이터 가져오기
  getTemplate(): Promise<any> {
    const url = 'template';
    return lastValueFrom(this.apiService.select(url));
  }

  // 인덱스드 디비에서 버전 데이터 가져오기
  async getVersionFromDB(version: number): Promise<TemplateData | undefined> {
    const db = await this.dbPromise;
    const result = await db.get('versionInfo', version.toString());
    return result?.data;
  }

  // 인덱스드 디비에 버전 데이터 저장하기
  async setVersionToDB(version: number, data: any): Promise<void> {
    const db = await this.dbPromise;
    await db.put('versionInfo', { version: version.toString(), data });
  }

  // 인덱스드 디비에서 버전 데이터 삭제하기
  async deleteVersionFromDB(version: number): Promise<void> {
    const db = await this.dbPromise;
    await db.delete('versionInfo', version.toString());
  }

  // 저장된 버전 가져오기
  async getStoredVersion(): Promise<number | null> {
    const db = await this.dbPromise;
    const allKeys = await db.getAllKeys('versionInfo');
    return allKeys.length > 0 ? Number(allKeys[0]) : null; // 키를 숫자로 변환하여 반환
  }

  // 버전 체크 및 템플릿 데이터 관리
  async checkAndUpdateTemplate(): Promise<void> {
    try {
      const response = await this.getVersion();
      const version = response.body;
      const storedVersion = await this.getStoredVersion();

      if (storedVersion?.toString() !== version.toString()) {
        await this.deleteVersionFromDB(storedVersion || 0);
        await this.fetchAndStoreTemplate(version);
      }
    } catch (error) {
      console.error('Error checking version:', error);
    }
  }

  // 템플릿 데이터를 가져와서 저장하기
  async fetchAndStoreTemplate(version: number): Promise<void> {
    try {
      const response = await this.getTemplate();
      const templateData = response.body;
      await this.setVersionToDB(version, templateData);
    } catch (error) {
      console.error('Error fetching template:', error);
    }
  }

  // 인덱스드 디비에서 템플릿 데이터 가져오기
  async getTemplateData(): Promise<TemplateData> {
    try {
      const storedVersion = await this.getStoredVersion();
      if (!storedVersion) throw new Error('No stored version found');

      const templateData = await this.getVersionFromDB(storedVersion);
      if (!templateData) throw new Error('No template data found for the stored version');

      return templateData;
    } catch (error) {
      console.error('Error getting template data:', error);
      throw error;
    }
  }
}
