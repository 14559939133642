import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  standalone: true,
  imports: [CommonModule],
  template: ` <div class="im-report-tooltip" [ngClass]="{ visible: visible }">{{ message }}</div> `,
  styles: `
    @import '../../../app.component.less';
    .im-report-tooltip {
      padding: 12px 24px;
      background-color: @gray900;
      white-space: pre-line;
      text-wrap: nowrap;
      color: @white;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      border-radius: 8px;
      box-shadow: @card01;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-bottom: 10px solid transparent;
        border-top: 10px solid @gray900;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    }
  `,
})
export class CustomTooltipComponent {
  @Input() visible: boolean = false;
  @Input() message: string = '';
}
