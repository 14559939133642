<article id="drive-directory">
  <h1 class="drive-directory-header">
    <div class="drive-directory-header-title">
      <img src="assets/icon/google_drive.png" alt="google drive icon" />
      <span>{{ type === 'sheets' ? '스프레드시트 선택' : '‘' + rawDataService.selectedRawData?._title + '’ 연동 위치 변경' }}</span>
    </div>
    <img (click)="onCancel()" src="assets/icon/close.svg" alt="close icon" />
  </h1>

  <mat-tab-group #tabGroup disableRipple (selectedTabChange)="onTabChange($event)" [preserveContent]="true" [mat-stretch-tabs]="false" class="drive-directory-tabs">
    <mat-tab>
      <ng-template mat-tab-label><span>내 드라이브</span></ng-template>
      <app-drive-tab-content
        #public
        [tabType]="'not-shared'"
        [type]="type"
        [driveData]="publicDriveData"
        [loader]="loading"
        (rootClick)="getGoogleDriveRootData('not-shared')"
        (onConfirmClick)="onConfirmSelectSheets($event)"
        (onCancelClick)="onCancel()"
        (loaderChange)="onLoaderChange($event)"
      ></app-drive-tab-content>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label><span>공유 문서함</span></ng-template>
      <app-drive-tab-content
        #shared
        [tabType]="'shared'"
        [type]="type"
        [driveData]="sharedDriveData"
        [loader]="loading"
        (rootClick)="getGoogleDriveRootData('shared')"
        (onConfirmClick)="onConfirmSelectSheets($event)"
        (onCancelClick)="onCancel()"
        (loaderChange)="onLoaderChange($event)"
      ></app-drive-tab-content
    ></mat-tab>
  </mat-tab-group>
</article>
