import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GoogleAuthService } from '../services/google-auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private googleAuthService: GoogleAuthService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthToken(req)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !req.url.includes('/token')) {
          return this.handle401Error(req, next);
        } else if (error.status === 499) {
          this.googleAuthService.signOut();
          localStorage.clear();
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  private addAuthToken(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = sessionStorage.getItem('_ac');
    if (accessToken) {
      return request.clone({
        setHeaders: { 'access-token': accessToken },
      });
    }
    return request;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const serviceAccessToken = sessionStorage.getItem('_ac')!;
      const serviceRefreshToken = sessionStorage.getItem('_rc')!;
      return this.refreshToken(serviceAccessToken, serviceRefreshToken).pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          sessionStorage.setItem('_ac', token);
          this.refreshTokenSubject.next(token);
          return next.handle(this.addAuthToken(request));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          return throwError(() => err);
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null && token !== undefined),
        take(1),
        switchMap(() => next.handle(this.addAuthToken(request))),
      );
    }
  }

  private refreshToken(serviceAccessToken: string, serviceRefreshToken: string): Observable<any> {
    const url = `https://${environment.baseURL}/token`;
    const params = {
      service_access_token: serviceAccessToken,
      service_refresh_token: serviceRefreshToken,
    };
    return this.http.get<any>(url, { params });
  }
}
