<article id="im-report-landing-main">
  <section id="ad-data" class="landing-wrap">
    <h2 class="landing-header">
      오늘도 보고서 만든다고
      <span><img src="assets/icon/naver.png" alt="naver icon" /> 네이버 검색광고 데이터</span>
      다운 받고 계시나요?
    </h2>
    <div class="landing-marquee">
      <div class="landing-marquee-contents play">
        @for (content of marqueeContents; track $index) {
          <div class="landing-marquee-content">
            <img src="{{ content.src }}" alt="{{ content.art }}" loading="lazy" />
            <div>
              <span class="landing-marquee-number">{{ $index + 1 }}.</span>{{ content.text }}
            </div>
          </div>
        }
      </div>
      <div aria-hidden="true" class="landing-marquee-contents play">
        @for (content of marqueeContents; track $index) {
          <div class="landing-marquee-content">
            <img src="{{ content.src }}" alt="{{ content.art }}" loading="lazy" />
            <div>
              <span class="landing-marquee-number">{{ $index + 1 }}.</span>{{ content.text }}
            </div>
          </div>
        }
      </div>
    </div>
    <p class="landing-header sub">
      <span>이런 단순 노동 작업을 하기엔</span>
      <span>당신의 시간은 귀중해요.</span>
    </p>
  </section>

  <section #parallaxContainer class="landing-parallax-container">
    <div #parallaxElement class="landing-parallax"></div>
    <div class="landing-parallax-content">
      <img src="assets/icon/logo_white.svg" alt="logo" class="logo-white" />
      <div class="landing-header parallax">
        <span
          >데이터<span class="parallax-point">자동갱신 <img src="assets/icon/ellipse_red.svg" alt="red dot" /></span>으로</span
        >
        <span>놀라운 작업 효율 개선을 경험해 보세요.</span>
      </div>
    </div>
  </section>

  <section class="landing-wrap side-padding">
    <div class="landing-header-wrap">
      <div class="landing-header-subtext">간단해요</div>
      <h2 class="landing-header">
        <span>내가 필요한 데이터 그룹만</span>
        <span>클릭해서 연동하기</span>
      </h2>
    </div>
    <img class="landing-image" src="assets/images/landing/ease.png" alt="클릭해서 데이터 연동하기 이미지" />
  </section>

  <section class="landing-wrap side-padding">
    <div class="landing-header-wrap">
      <div class="landing-header-subtext">유용해요</div>
      <h2 class="landing-header">
        <span>데이터와 관련된 다양한 형태의</span>
        <span>시각화 보고서 삽입하기</span>
      </h2>
    </div>
    <img class="landing-image" src="assets/images/landing/templates.png" alt="리포트 템플릿 선택 이미지" />
  </section>

  <section class="landing-wrap side-padding">
    <div class="landing-header-wrap">
      <div class="landing-header-subtext">그런데 어디에 연동해요?</div>
      <h2 class="landing-header">
        <span>구글 드라이브나</span>
        <span>기존에 작성하던 스프레드시트에</span>
        <span>연동하기</span>
      </h2>
    </div>
    <img class="landing-image" src="assets/images/landing/drive.png" alt="저장할 구글 드라이브 선택 이미지" />
  </section>

  <section id="templates" class="landing-wrap">
    <div class="landing-header-wrap">
      <div class="landing-header-subtext">그래서 데이터가 어떻게 보이나요?</div>
      <h2 class="landing-header">
        <span>전문 마케터가 엄선한</span>
        <span>데이터 그룹과 무료 템플릿을</span>
        <span>구경해 보세요.</span>
      </h2>
      <div class="landing-header-info">미리보기를 <strong>클릭</strong>해 무료 템플릿을 확인해 보세요!</div>
    </div>

    <div class="landing-marquee report" (mouseover)="play = false" (mouseleave)="play = true">
      <div class="landing-marquee-contents play" [ngStyle]="{ animationPlayState: play ? 'running' : 'paused' }">
        @for (report of reports; track $index) {
          <div class="landing-marquee-report">
            <div class="landing-marquee-report-name">
              <img src="assets/icon/excel.svg" alt="excel icon" />
              <span>{{ report.name }}</span>
            </div>
            <div class="landing-marquee-report-link">
              <img src="assets/icon/excel_bg.svg" alt="excel sheets" />
              <button (click)="onClickPreview(report.src)" class="landing-marquee-report-router">
                <span>미리보기</span>
                <img src="assets/icon/external.svg" alt="external icon" />
              </button>
            </div>
          </div>
        }
      </div>
      <div class="landing-marquee-contents play" [ngStyle]="{ animationPlayState: play ? 'running' : 'paused' }">
        @for (report of reports; track $index) {
          <div class="landing-marquee-report">
            <div class="landing-marquee-report-name">
              <img src="assets/icon/excel.svg" alt="excel icon" />
              <span>{{ report.name }}</span>
            </div>
            <div class="landing-marquee-report-link">
              <img src="assets/icon/excel_bg.svg" alt="excel sheets" />
              <button (click)="onClickPreview(report.src)" class="landing-marquee-report-router">
                <span>미리보기</span>
                <img src="assets/icon/external.svg" alt="external icon" />
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  </section>
  <section id="im-report-landing-footer">
    <img src="assets/icon/logo_white.svg" alt="logo" class="logo-white" />
    <h2>
      <span>데이터 연동 클릭 한번으로</span>
      <span>당신은 <strong class="point">보고만 받으세요.</strong></span>
    </h2>
    <button (click)="signIn()">지금 바로 <span class="point">무료로</span> 사용하기</button>
  </section>
</article>
