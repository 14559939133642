import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { GoogleAuthService } from '../../../core/services/google-auth.service';
import { UserService } from '../../../core/services/user.service';
import { GenericService } from '../../services/generic.service';

@Component({
  selector: 'app-im-report-gnb',
  template: `
    <section
      class="im-report-gnb"
      [ngClass]="{ login: isLoginVisible && !googleAuthService.loginLoading, mobile: genericService.isMobile() }"
      [ngStyle]="{ display: this.hide ? 'none' : 'flex' }"
    >
      <h1 (click)="routeMainPage()" class="im-report-logo">
        @if (!genericService.isMobile()) {
          <img src="assets/icon/logo_black.svg" alt="im-report.co" />
        } @else {
          <img src="assets/icon/logo_mo.svg" alt="im-report.co" />
        }
      </h1>
      @if (!userService.user) {
        <div class="im-report-gnb-menu">
          <button (click)="signInWithGoogle()" class="im-report-google-login"><img src="assets/icon/google.png" alt="google icon" />구글 로그인</button>
        </div>
      } @else {
        <section class="im-report-gnb-user">
          <div [matMenuTriggerFor]="gnbMenu" class="im-report-gnb-user-info">계정 관리</div>
          <mat-menu #gnbMenu="matMenu" [xPosition]="'after'" class="im-report-gnb-user-menu" style="margin-top:4px;">
            @if (!genericService.isMobile()) {
              <div routerLink="accounts" mat-menu-item>계정 변경</div>
            }
            <div mat-menu-item (click)="googleAuthService.signOut()">로그아웃</div>
          </mat-menu>
          <img src="{{ userService.user.thumbnail_url }}" alt="user profile thumbnail" class="im-report-gnb-user-thumb" />
        </section>
      }
    </section>
  `,
  styles: `
    @import '../../../app.component.less';
    .im-report {
      &-gnb {
        .flex-row (space-between, 0);
        padding: 8px 24px;
        background-color: @white;
        height: 54px;
        &.login {
          background-color: @gray50;
        }
        &-user {
          .flex-row (@gap:16px);
          &-info {
            .button (@width:84px, @bg-color:@bg-alternative);
            .head-typo ();
            .flex-row ();
          }
          &-thumb {
            width:32px;
            height:32px;
            .radius(50%);
          }
        }
      }
      &-logo {
        cursor: pointer;
        max-width: 164px;
      }
      &-google-login {
        .flex-row (@gap: 8px);
        .button (@width:125px, @bg-color: @bg-alternative);
        .head-typo ();
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    @media screen and (max-width: 479px) {
      .im-report {
        &-gnb {
          padding: 8px 20px;
        }
      }
    }
  `,
})
export class GnbComponent {
  isLoginVisible: boolean = false;
  hide: boolean = false;
  constructor(
    private router: Router,
    public googleAuthService: GoogleAuthService,
    public userService: UserService,
    public genericService: GenericService,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginVisible = this.checkIfLoginVisible(event.urlAfterRedirects);
        this.hide = this.checkIfHide(event.urlAfterRedirects);
      }
    });
    // this.googleAuthService.handleAuthCallback('dvp7pb74');
  }

  private checkIfLoginVisible(url: string): boolean {
    return url.includes('/login') || url === '/';
  }

  private checkIfHide(url: string): boolean {
    return url.includes('/privacy-policy');
  }

  signInWithGoogle() {
    this.googleAuthService.signIn();
  }

  routeMainPage() {
    this.router.navigate([this.userService.user?.has_naver_raw_data ? '/my-data' : '/home']);
  }
}
