export interface NaverAccount {
  naver_login_id: string;
  id: number;
}

export interface ManagerAccounts {
  naver_manager_account_id: number;
  id: number;
  naver_customer_id: string;
  naver_nickname: string;
  naver_login_id: string;
}

export interface ConnectedManagerAccount {
  access_license: string;
  naver_customer_id: string;
  secret_key: string;
}

export enum Platform {
  NAVER = 1,
  KAKAO = 2,
}
