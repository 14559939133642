import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { GoogleAuthService } from '../../core/services/google-auth.service';
import { NaverAccountService } from '../../shared/services/naver-account.service';
import { UserService } from '../../core/services/user.service';

import { PlatformDropdownComponent } from '../../shared/components/platform-dropdown/platform-dropdown.component';
import { AccountDropdownComponent } from '../../shared/components/account-dropdown/account-dropdown.component';
import { RawDataTemplateComponent } from '../../shared/components/raw-data-template/raw-data-template.component';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.less',
  imports: [CommonModule, RouterModule, PlatformDropdownComponent, AccountDropdownComponent, RawDataTemplateComponent],
})
export class HomeComponent {
  constructor(
    private googleAuthService: GoogleAuthService,
    private naverAccountService: NaverAccountService,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    this.googleAuthService.loginLoading = false;
    this.userService.user?.has_naver_manager_account && this.naverAccountService.getNaverAdvertisingAccounts().subscribe();
  }
}
