import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { ConnectedNaverDialogComponent } from './connected-naver-dialog/connected-naver-dialog.component';
import { GoogleDriveDirectoryDialogComponent } from './google-drive-directory-dialog/google-drive-directory-dialog.component';
import { DriveTabContentComponent } from './google-drive-directory-dialog/drive-tab-content/drive-tab-content.component';

@NgModule({
  declarations: [ConfirmationDialogComponent, ReportDialogComponent, ConnectedNaverDialogComponent, GoogleDriveDirectoryDialogComponent],
  imports: [CommonModule, FormsModule, MatDialogModule, MatCheckboxModule, MatTabsModule, DriveTabContentComponent],
})
export class DialogsModule {}
