<article id="im-report-home" class="setting-article">
  @if (!userService.user?.has_naver_manager_account) {
    <app-platform-dropdown style="display: block; padding-bottom: 28px"></app-platform-dropdown>
  }

  <h2 class="im-report-home-header">
    원하는 데이터 항목을 스프레드시트에<br />
    연동하고 출근 전에 업데이트 받으세요!
  </h2>

  @if (!userService.user?.has_naver_manager_account) {
    <section class="im-report-home-naver-login">
      <p class="im-report-home-naver-summary">
        데이터 연동을 위해<br />
        계정을 연결해 주세요.
      </p>
      <button routerLink="/accounts" class="im-report-home-naver-connect"><img src="assets/icon/naver.png" alt="naver icon" /> 네이버 계정 연결</button>
    </section>
  }

  <section class="im-report-home-guide-line">
    <div class="im-report-home-guide">
      <img src="assets/images/guide_report.png" alt="select report" class="im-report-home-guide-img" />
      <div class="im-report-home-guide-inner">
        <h4 class="im-report-home-guide-title">리포트 선택</h4>
        <p class="im-report-home-guide-description">원하는 데이터 조합을 갖춘 리포트를 선택하세요.</p>
      </div>
    </div>
    <div class="im-report-home-guide">
      <img src="assets/images/guide_sheet.png" alt="select report" class="im-report-home-guide-img" />
      <div class="im-report-home-guide-inner">
        <h4 class="im-report-home-guide-title">구글 스프레드시트 선택</h4>
        <p class="im-report-home-guide-description">데이터를 연동할 구글 스프레드시트를 선택하세요.</p>
      </div>
    </div>
    <div class="im-report-home-guide">
      <img src="assets/images/guide_connect.png" alt="select report" class="im-report-home-guide-img" />
      <div class="im-report-home-guide-inner">
        <h4 class="im-report-home-guide-title">데이터 연동 끝</h4>
        <p class="im-report-home-guide-description">매일 업데이트되는 데이터를 구글 시트에서 확인하세요.</p>
      </div>
    </div>
  </section>

  <section class="im-report-home-contents" [ngStyle]="{ gap: !userService.user?.has_naver_manager_account ? '80px' : '40px' }">
    @if (!userService.user?.has_naver_manager_account) {
      <h2 class="im-report-home-contents-header">
        ⏰ 매일 <strong>오전 7시 30분</strong> 업데이트! <br />
        스프레드시트에 연동할 데이터 리포트를 선택해 주세요.
      </h2>
    } @else {
      <div class="im-report-home-contents-header-wrap">
        <app-platform-dropdown></app-platform-dropdown>
        <div class="im-report-home-contents-header-info"><img src="assets/icon/clock.svg" alt="clock icon" /><span>매일 오전 7시 30분 업데이트</span></div>
      </div>
      <app-account-dropdown></app-account-dropdown>
    }

    <app-sample-raw-data [addData]="false"></app-sample-raw-data>
  </section>
</article>
