<article id="im-report-add-data" class="setting-article">
  <section class="im-report-header">
    <h1 class="im-report-header-title"><img onclick="history.back()" src="assets/icon/back.svg" alt="back arrow" />연동 데이터 추가</h1>
    <button routerLink="/my-data" class="im-report-header-route">나의 데이터 목록</button>
  </section>

  <section class="im-report-add-data-platform">
    <app-platform-dropdown></app-platform-dropdown>
    <app-account-dropdown (selectAccount)="getRawData()"></app-account-dropdown>
  </section>

  <app-sample-raw-data [addData]="true" [activeRawData]="activeRawData"></app-sample-raw-data>
</article>
