<article id="im-report-my-data" class="setting-article">
  <h1 class="im-report-my-data-header">나의 데이터</h1>
  <section class="im-report-my-data-platform">
    <app-platform-dropdown></app-platform-dropdown>
    @if (myRawData().length) {
      <div class="im-report-my-data-platform-button-wrap">
        <button
          (click)="activeRawData.length === 6 ? genericService.return() : onClickAddData()"
          (mouseover)="buttonHover = true"
          (mouseleave)="buttonHover = false"
          class="im-report-my-data-platform-button"
          [ngClass]="{ disabled: activeRawData.length === 6 }"
        >
          <img src="assets/icon/plus.svg" alt="plus icon" />연동 데이터 추가
        </button>
        @if (buttonHover && activeRawData.length === 6) {
          <app-custom-tooltip
            [message]="
              '현재 제공하는 데이터 리포트를
              모두 사용 중이에요.'
            "
            [@tooltip]
          ></app-custom-tooltip>
        }
      </div>
    }
  </section>
  <section class="im-report-my-data-accounts">
    <div class="im-report-my-data-dropdown">
      <app-account-dropdown (selectAccount)="getRawData()"></app-account-dropdown>
      @if (importing) {
        <div class="im-report-my-data-dropdown-importing" [@tooltip]>
          <img src="assets/icon/mail.svg" alt="mail icon" />
          <span>데이터 연동이 완료되면 <strong>로그인한 이메일로 연동 완료 이메일을 보내드려요.</strong></span>
        </div>
      }
    </div>
    <div class="im-report-my-data-updated">
      <img src="assets/icon/clock.svg" alt="clock icon" />
      <p>매일 오전 7시 30분 업데이트</p>
    </div>
  </section>

  @if (activeRawData.length === 4 && naverAccountService.AdvertisingAccounts.length > 1) {
    <section class="im-report-my-data-maximum">
      <strong>모든 데이터 연동 중!</strong>
      동일한 데이터 항목으로 다른 보고서도 보고 싶으시다면, 광고 계정을 변경해 데이터를 연동해 보세요.
    </section>
  }

  <section class="im-report-my-data-content" [ngClass]="myRawData().length ? 'raw-data-content-wrap' : 'raw-data-content-empty'">
    @for (rawData of myRawData(); track $index) {
      <section class="raw-data-content">
        @if (rawData.raw_data._templates && rawData.raw_data._templates.length) {
          @if (rawData.importing_status === RawDataImportStatus.SUCCESS || rawData.importing_status === RawDataImportStatus.FAILED) {
            <div (click)="openDialogReport(rawData.raw_data)" class="raw-data-content-template" [@buttons]>
              @if (rawData.raw_data.use_templates.length) {
                사용 중인 템플릿 <span>{{ rawData.raw_data.use_templates.length }}개</span>
              } @else {
                <img src="assets/icon/plus_gray.svg" alt="plus icon" /> 템플릿 추가
              }
            </div>
          }
        }
        <div class="raw-data-content-hover">
          @if (rawData.importing_status < RawDataImportStatus.SUCCESS && rawData.importing_status > RawDataImportStatus.FAILED) {
            <div class="raw-data-content-hover-content" [@tooltip]>
              <div class="raw-data-content-importing">
                <span class="loader"></span>
                @if (rawData.importing_status < RawDataImportStatus.ADDING) {
                  <swiper-container
                    #swiperEl
                    slides-per-view="1"
                    slides-per-group="1"
                    direction="vertical"
                    loop="true"
                    autoplay="true"
                    delay="2000"
                    speed="1000"
                    disable-on-interaction="false"
                    allow-touch-move="false"
                  >
                    <swiper-slide
                      ><p class="raw-data-content-importing">{{ rawData.raw_data._title }} 데이터 연동 진행중. .</p></swiper-slide
                    >
                    <swiper-slide><p class="raw-data-content-importing">연동까지 최대 3시간이 걸릴 수 있어요.</p></swiper-slide>
                    <swiper-slide><p class="raw-data-content-importing">페이지를 떠나도 연동은 계속돼요.</p></swiper-slide>
                  </swiper-container>
                }
                @if (rawData.importing_status === RawDataImportStatus.ADDING) {
                  <p class="raw-data-content-importing">템플릿 추가 중. .</p>
                }
              </div>
            </div>
          }
          <img src="{{ rawData.raw_data._thumbnail }}" alt="raw data thumbnail" class="raw-data-content-thumbnail" />
        </div>
        <div class="raw-data-content-info">
          <h3 class="raw-data-content-title">
            <img
              src="{{ !rawData.update_status || rawData.importing_status === RawDataImportStatus.FAILED ? 'assets/icon/warning.svg' : 'assets/icon/play.svg' }}"
              alt="update status"
            />
            <div (click)="rawData.importing_status === RawDataImportStatus.SUCCESS ? routerSheet(rawData.raw_data) : genericService.return()">
              {{ rawData.raw_data._title }}
              <img src="assets/icon/arrow_right.svg" alt="right arrow" />
            </div>
          </h3>

          <p class="raw-data-content-description">
            @switch (rawData.importing_status) {
              @case (RawDataImportStatus.SUCCESS) {
                ‘{{ rawData.raw_data.spreadsheet_name }}’에 연동 중
              }
              @case (RawDataImportStatus.FAILED) {
                스프레드시트에 연동 실패
              }
              @case (RawDataImportStatus.ADDING) {
                템플릿 추가 중. .
              }
            }
          </p>

          <p class="raw-data-content-conversion">(전환값 기준 : {{ rawDataService.conversionType[rawData.raw_data.conversion_type] }})</p>

          <div class="raw-data-content-status">
            <p class="raw-data-content-status-updated-at">
              @if (rawData.raw_data.last_updated_at) {
                {{ rawData.raw_data.last_updated_at | timeAgo }} 업데이트
              }
            </p>

            @if (rawData.importing_status === RawDataImportStatus.SUCCESS || rawData.importing_status === RawDataImportStatus.FAILED) {
              <div class="raw-data-content-status-button" [@buttons]>
                <button (click)="deletedRawData(rawData.raw_data.id)" class="deleted">삭제</button>
                @if (!rawData.update_status && rawData.importing_status === RawDataImportStatus.FAILED && rawData.is_first) {
                  <button (click)="handleRawDataAction('retry/first', rawData)" class="primary" (mousemove)="rawData._hover = true" (mouseleave)="rawData._hover = false">
                    <img src="assets/icon/play_white.svg" alt="play icon white" />연동 재시도
                    @if (rawData._hover) {
                      <app-custom-tooltip
                        [message]="
                          '연동에 실패했어요.
                          다시 시도해 주세요.'
                        "
                        [@tooltip]
                      ></app-custom-tooltip>
                    }
                  </button>
                }
                @if (!rawData.update_status && rawData.importing_status === RawDataImportStatus.FAILED && !rawData.is_first) {
                  <button (click)="handleRawDataAction('retry/update', rawData)" class="primary" (mousemove)="rawData._hover = true" (mouseleave)="rawData._hover = false">
                    <img src="assets/icon/play_white.svg" alt="play icon white" /> 데이터 재연동
                    @if (rawData._hover) {
                      <app-custom-tooltip
                        [message]="
                          rawData.update_status_reason === UpdateStatus.DELETED
                            ? '스프레드시트가
                              삭제되었어요.'
                            : '연동에 실패했어요.
                              다시 시도해 주세요.'
                        "
                        [@tooltip]
                      ></app-custom-tooltip>
                    }
                  </button>
                }
                @if (rawData.update_status && rawData.importing_status === RawDataImportStatus.SUCCESS) {
                  <button (click)="handleRawDataAction('update', rawData)" class="gray">
                    <img src="assets/icon/refresh.svg" alt="refresh white" />
                    업데이트
                  </button>
                }
              </div>
            }
          </div>
        </div>
      </section>
    } @empty {
      @if (dataEmpty) {
        <div class="raw-data-empty">
          <p>연동 중인 데이터가 없어요.</p>
          <button (click)="activeRawData.length === 4 ? genericService.return() : onClickAddData()" class="im-report-my-data-platform-button">
            <img src="assets/icon/plus.svg" alt="plus icon" />데이터 연동하기
          </button>
        </div>
      }
    }
  </section>
</article>
