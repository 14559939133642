<article class="drive-content">
  @if (type === 'sheets') {
    <input
      [(ngModel)]="searchContent"
      (keydown.enter)="searchDirectory(); $event.preventDefault()"
      type="text"
      placeholder="시트명을 검색하거나 URL을 입력해 주세요."
      class="im-report-input"
    />
  }

  <section class="drive-content-scroll">
    @if (!loader && driveData) {
      @if (history.length) {
        <section class="drive-content-nav">
          <div (click)="onClickRootDirectory()" class="drive-content-nav-root">내 드라이브</div>
          <img src="assets/icon/arrow_right.svg" alt="arrow right" />
          @for (folder of history; track $index) {
            <div (click)="getSubDirectory(folder)" class="drive-content-nav-root">{{ folder.name }}</div>
            @if ($index < history.length - 1) {
              <img src="assets/icon/arrow_right.svg" alt="arrow right" />
            }
          }
        </section>
      }
      @if (driveData.folders.length) {
        <section class="drive-content-folder" [@loader]>
          <P class="drive-content-folder-title sub-text">{{ type === 'sheets' ? '폴더' : '보고서를 연동할 폴더를 선택해 주세요.' }}</P>
          <div class="drive-content-folder-wrap">
            @for (folder of driveData.folders; track $index) {
              <div
                (click)="selectedFolder = folder"
                (dblclick)="getSubDirectory(folder)"
                (mouseover)="folder._hover = true"
                (mouseleave)="folder._hover = false"
                class="drive-content-folder-info"
                [ngClass]="{ hovering: folder._hover, active: type === 'directory' && selectedFolder === folder }"
              >
                <div class="drive-content-folder-name">
                  <img src="assets/icon/folder.svg" alt="folder icon" />
                  <span>{{ folder.name }}</span>
                </div>
                <img src="assets/icon/arrow_right.svg" alt="arrow right" class="drive-content-folder-arrow" />
              </div>
            }
          </div>
        </section>
      }
      @if (driveData.files.length && type === 'sheets') {
        <section class="drive-content-file" [@loader]>
          <P class="drive-content-file-title sub-text">파일</P>
          @for (file of driveData.files; track $index) {
            <div
              (click)="selectedFile = file"
              (mouseover)="file._hover = true"
              (mouseleave)="file._hover = false"
              class="drive-content-file-info"
              [ngClass]="{ hovering: file._hover, active: selectedFile === file }"
            >
              <img src="assets/icon/excel.svg" alt="spreadsheet icon" />
              <span>{{ file.name }}</span>
            </div>
          }
        </section>
      }
      @if (!contentEmpty) {
        @if (type === 'directory' && !driveData.folders.length) {
          <p class="drive-content-empty" [@loader]>
            연동할 폴더가 없어요
            @if (selectedFolder) {
              <br />‘여기에 연동’을 클릭해 주세요.
            }
          </p>
        }
        @if (type === 'sheets' && !driveData.files.length) {
          <p class="drive-content-empty" [@loader]>파일이 없어요.</p>
        }
      }
      @if (searchContent && contentEmpty) {
        <div class="drive-content-empty-search" [@loader]>
          <p>일치하는 결과가 없어요.</p>
          <button (click)="onClickRootDirectory(); searchContent = ''"><img src="assets/icon/search_refresh.svg" alt="refresh icon" /> 검색 초기화</button>
        </div>
      }
    } @else if (loader) {
      <div class="loader-container" [@loader]>
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    }
  </section>

  <section class="drive-content-footer">
    @if (type === 'sheets') {
      <p class="drive-content-footer-text">
        @if (!selectedFile) {
          <span class="sub" [@textFade]>로우 데이터를 연동할 스프레드시트를 선택해 주세요.</span>
        } @else {
          <span class="primary" [@textFade]>선택한 스프레드시트에 로우데이터를 연동할게요.</span>
        }
      </p>
      <div class="drive-content-footer-button-wrap">
        <button (click)="onCancel()" class="drive-content-footer-button cancel">취소</button>
        <button (click)="onConfirm()" class="drive-content-footer-button sheets" [ngClass]="{ disabled: !selectedFile }">이 스프레드시트에 연동</button>
      </div>
    } @else {
      <p class="drive-content-footer-text"><span class="sub">로우 데이터를 연동할 폴더를 선택해 주세요.</span></p>
      <div class="drive-content-footer-button-wrap">
        <button (click)="onCancel()" class="drive-content-footer-button cancel">취소</button>
        <button (click)="onConfirm()" class="drive-content-footer-button folder" [ngClass]="{ disabled: !selectedFolder }">여기에 연동</button>
      </div>
    }
  </section>
</article>
