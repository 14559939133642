<article id="connected-naver">
  <h2 class="connected-naver-title">
    <div class="connected-naver-title-text"><img src="assets/icon/naver.png" alt="naver icon" />네이버 검색광고 관리 계정 연결</div>
    <img (click)="onCancel()" src="assets/icon/close.svg" alt="close icon" />
  </h2>

  <section class="connected-naver-guide-wrap">
    @if (focusedInput === FocusedInput.CUSTOMER_ID) {
      <img src="assets/images/naver_customer.png" alt="customer id guide" [@imageFade] />
    } @else {
      @if (accessGuide === 'login') {
        <img src="assets/images/naver_access.png" alt="access license guide" [@imageFade] />
        <span (click)="accessGuide = 'copy'" class="navigation">다음</span>
      } @else {
        <img src="assets/images/naver_secret.png" alt="secret key guide" [@imageFade] />
        <span (click)="accessGuide = 'login'" class="navigation">이전</span>
      }
    }
  </section>

  <section class="connected-naver-form">
    <div class="connected-naver-input">
      <h2 class="connected-naver-input-title">customer ID<span>*</span></h2>
      <input
        #idInput
        [(ngModel)]="connectedManagerAccount.naver_customer_id"
        (ngModelChange)="checkInputs()"
        (focus)="focusedInput = FocusedInput.CUSTOMER_ID"
        class="im-report-input"
        type="text"
        name=""
        id=""
      />
    </div>
    <div class="connected-naver-input">
      <h2 class="connected-naver-input-title">액세스 라이선스<span>*</span></h2>
      <input
        [(ngModel)]="connectedManagerAccount.access_license"
        (ngModelChange)="checkInputs()"
        (focus)="focusedInput = FocusedInput.ACCESS"
        class="im-report-input"
        type="text"
        name=""
        id=""
      />
    </div>
    <div class="connected-naver-input">
      <h2 class="connected-naver-input-title">비밀키<span>*</span></h2>
      <input
        [(ngModel)]="connectedManagerAccount.secret_key"
        (ngModelChange)="checkInputs()"
        (focus)="focusedInput = FocusedInput.ACCESS"
        class="im-report-input"
        type="text"
        name=""
        id=""
      />
    </div>
  </section>

  <section class="connected-naver-button-wrap">
    @if (isConnected === false) {
      <div class="connected-naver-error" [@errorFade]>네이버 계정 정보를 확인해주세요.</div>
    }
    <button (click)="onSubmit()" class="connected-naver-button" [ngClass]="{ disabled: !isFormValid }">연결</button>
  </section>
</article>
