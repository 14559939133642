import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';

import { GoogleAuthService } from '../../core/services/google-auth.service';
import { NaverAccountService } from '../../shared/services/naver-account.service';
import { UserService } from '../../core/services/user.service';

import { ConfirmationDialogComponent } from '../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-accounts',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './accounts.component.html',
  styleUrl: './accounts.component.less',
})
export class AccountsComponent {
  constructor(
    public googleAuthService: GoogleAuthService,
    public naverAccountService: NaverAccountService,
    public userService: UserService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {
    //this.naverAccountService.openNaverConnectDialog();
    this.userService.getManagerAccounts();
  }

  openDialogNaverAccountManagement() {
    if (this.userService.user!.has_naver_manager_account) this.openChangeNaverAccount();
    else this.naverAccountService.getConnectedManagerAccount();
  }

  openChangeNaverAccount() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '460px' });
    const dialogInstance = dialogRef.componentInstance;
    dialogInstance.title = '네이버 검색광고 계정을 변경하시겠어요?';
    dialogInstance.description = '계정 변경 시, 기존의 스프레드시트의 데이터는 더 이상 업데이트되지 않아요.';
    dialogInstance.cancelText = '취소';
    dialogInstance.confirmText = '변경';

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.naverAccountService.getConnectedManagerAccount();
    });
  }

  routerAddData() {
    this.router.navigate([this.userService.user?.has_naver_raw_data ? '/my-data' : '/home']);
  }
}
