import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-platform-dropdown',
  standalone: true,
  imports: [CommonModule, MatMenuModule],
  template: `
    <article id="platform-dropdown">
      <section [matMenuTriggerFor]="platformDropdownOption" #trigger="matMenuTrigger" class="platform-dropdown-select" [ngClass]="{ open: trigger.menuOpen }">
        <div class="platform-dropdown-select-wrap"><img class="platform-dropdown-icon" src="{{ selectPlatform.icon }}" alt="platform icon" /> {{ selectPlatform.title }} 광고</div>
        <img src="assets/icon/arrow_dropdown.svg" alt="dropdown arrow" class="platform-dropdown-select-arrow" />
      </section>
      <mat-menu #platformDropdownOption="matMenu" class="platform-dropdown-option-wrap">
        @for (platform of platforms; track $index) {
          <div
            (click)="selectedPlatform(platform)"
            mat-menu-item
            class="platform-dropdown-option"
            [ngClass]="{ active: selectPlatform.title === platform.title, disabled: platform.isComing }"
          >
            <div class="platform-dropdown-option-content"><img class="platform-dropdown-icon" src="{{ platform.icon }}" alt="platform icon" /> {{ platform.title }}</div>
            @if (platform.isComing) {
              <span class="platform-dropdown-option-coming">준비 중</span>
            }
          </div>
        }
      </mat-menu>
    </article>
  `,
  styles: `
    @import '../../../app.component.less';
    #platform-dropdown {
      .flex-row ();
      .platform-dropdown {
        &-select {
          .flex-row (space-between, 10px);
          .head-typo (2.8rem, 3.8rem);
          border-bottom: 3px solid @admin-primary;
          border-radius: 8px 8px 0 0;
          transition: background-color 0.3s ease;
          cursor: pointer;
          padding: 10px 15px;
          width: 221px;
          text-wrap: nowrap;
          &:hover {
            background-color: @violet40;
          }
          &.open {
            background-color: @white;
            box-shadow: @card03;
          }
          &-wrap {
            .flex-row (@gap: 10px);
          }
        }
      }
    }
    ::ng-deep {
      .platform-dropdown-icon {
        width: 24px;
        height: 24px;
      }
      .mat-mdc-menu-panel.platform-dropdown-option-wrap {
        width: 221px;
        margin-top: 0;
        box-shadow: @card03;
        border-radius: 0 0 8px 8px;
      }
      .mat-mdc-menu-item.platform-dropdown-option {
        padding: 12px 15px;
        border-radius: 0;
        background-color: @white;
        &:hover {
          color: inherit;
        }
        &.active {
          background-color: @violet40;
        }
        &.disabled {
          pointer-events: none;
          .platform-dropdown-option-content {
            opacity: 0.5;
          }
        }
        .mat-mdc-menu-item-text {
          .flex-row (space-between, 15px);
        }
        .platform-dropdown-option-content {
          .flex-row (flex-start, 15px);
          .head-typo (1.6rem, 2.2rem);
          color: @text-sub;
        }
        .platform-dropdown-option-coming {
          color: @white;
          background-color: @admin-primary;
          padding: 2px 7px;
          font-weight: 700;
          font-size: 1rem;
          .radius (100px);
        }
      }
      .mat-mdc-menu-content:has(.platform-dropdown-option) {
        padding: 0 !important;
      }
    }
  `,
})
export class PlatformDropdownComponent {
  platforms: Platform[] = [
    { icon: 'assets/icon/naver.png', title: '네이버', isComing: false },
    { icon: 'assets/icon/kakao.png', title: '카카오', isComing: true },
    { icon: 'assets/icon/meta.png', title: '메타', isComing: true },
    { icon: 'assets/icon/tiktok.png', title: '틱톡', isComing: true },
  ];

  selectPlatform: Platform = this.platforms[0];

  selectedPlatform(platForm: Platform) {
    if (platForm.isComing) return;
    this.selectPlatform = platForm;
  }
}
interface Platform {
  icon: string;
  title: string;
  isComing: boolean;
}
