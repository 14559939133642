<div class="raw-data-content-wrap">
  @for (rawData of rawDataService.sampleRawData; track $index) {
    @if (!addData || (addData && !activeRawData?.includes(rawData._raw_data_format))) {
      <section class="raw-data-content" [ngClass]="{ hovering: rawData._hover }" (mouseover)="rawData._hover = true" (mouseleave)="rawData._hover = false">
        @if (rawData._templates.length) {
          <div (click)="this.rawDataService.openDialogReport(rawData, 'preview'); $event.stopPropagation()" class="raw-data-content-template">
            추천 템플릿 <span>{{ rawData._templates.length }}개</span>
          </div>
        }
        <div class="raw-data-content-hover">
          <img src="{{ rawData._thumbnail }}" alt="raw data thumbnail" class="raw-data-content-thumbnail" />
          @if (rawData._hover) {
            <div class="raw-data-content-hover-content" [@contentHover]>
              @if (!userService.user?.has_naver_manager_account) {
                <img src="assets/icon/lock.svg" alt="lock icon" />
                <p>계정을 연동해 주세요.</p>
              } @else {
                <button (click)="onRouterPreview(rawData)" class="raw-data-content-hover-preview">
                  스프레드시트 미리 보기
                  <img src="assets/icon/external.svg" alt="external link" />
                </button>
              }
            </div>
          }
        </div>
        <div class="raw-data-content-info">
          <h3 class="raw-data-content-title">
            {{ rawData._title }} <span class="raw-data-content-period">{{ rawData._period }}</span>
          </h3>
          <p class="raw-data-content-description">{{ rawData._description }}</p>
          <div class="raw-data-content-standard">
            <img src="{{ rawData._standard }}" alt="raw data standard" />
          </div>
          <button (click)="onClickButton(rawData); $event.stopPropagation()" class="raw-data-content-export" [ngClass]="{ link: userService.user?.has_naver_manager_account }">
            @if (userService.user?.has_naver_manager_account) {
              <img src="assets/icon/link.svg" alt="export button" />데이터 연동하기
            } @else {
              <img src="assets/icon/naver.png" alt="naver icon" />계정 연결하기
            }
          </button>
        </div>
      </section>
    }
  }
</div>

<div class="raw-data-connection-info">
  <div class="raw-data-connection-info-header">검색어 및 키워드 성과 연동 관련 안내</div>
  <ul class="raw-data-connection-info-description">
    <li>‘검색어' 성과 데이터는 네이버 검색광고 API 시스템에서 제공되지 않기 때문에 데이터를 불러올 수 없습니다.</li>
    <li>‘키워드'를 기준으로 데이터를 확인할 때, 키워드 설정이 없는 ‘쇼핑검색광고'나 ‘플레이스' 같은 광고 유형의 키워드 데이터는 보여지지 않으며, 해당 부분은 ‘-’로 표기됩니다.</li>
  </ul>
</div>
