import { Routes } from '@angular/router';
import { authGuard } from '../core/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AccountsComponent } from './accounts/accounts.component';
import { MyDataComponent } from './my-data/my-data.component';
import { AddDataComponent } from './add-data/add-data.component';
import { MobileComponent } from './mobile/mobile.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

export const featureRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'home', component: HomeComponent, canActivate: [authGuard] },
  { path: 'accounts', component: AccountsComponent, canActivate: [authGuard] },
  { path: 'my-data', component: MyDataComponent, canActivate: [authGuard] },
  { path: 'add-data', component: AddDataComponent, canActivate: [authGuard] },
  { path: 'mobile', component: MobileComponent, canActivate: [authGuard] },
  { path: '**', redirectTo: '' },
];
