import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GoogleDriveData } from '../../../models/google-drive.model';

import { RawDataService } from '../../../services/raw-data.service';
import { ApiService } from '../../../../core/services/api.service';
import { finalize, of, Subject, switchMap, tap } from 'rxjs';
import { DriveTabContentComponent } from './drive-tab-content/drive-tab-content.component';

@Component({
  selector: 'app-google-drive-directory-dialog',
  templateUrl: './google-drive-directory-dialog.component.html',
  styleUrl: './google-drive-directory-dialog.component.less',
})
export class GoogleDriveDirectoryDialogComponent {
  type!: 'sheets' | 'directory';
  publicDriveData!: GoogleDriveData;
  sharedDriveData!: GoogleDriveData;
  loading: boolean = false;
  private driveRequest$ = new Subject<{ drive: 'not-shared' | 'shared'; root?: boolean }>();
  @ViewChild('public') private publicComponent!: DriveTabContentComponent;
  @ViewChild('shared') private sharedComponent!: DriveTabContentComponent;

  constructor(
    public rawDataService: RawDataService,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<GoogleDriveDirectoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { type: 'sheets' | 'directory' },
  ) {
    this.type = this.data.type;
    this.initializeDriveRequestSubscription();
  }

  ngOnInit(): void {
    this.getGoogleDriveRootData('not-shared');
  }

  private initializeDriveRequestSubscription() {
    this.driveRequest$
      .pipe(
        tap(() => (this.loading = true)),
        switchMap(({ drive }) =>
          this.fetchDriveData(drive).pipe(
            finalize(() => {
              this.loading = false;
            }),
          ),
        ),
      )
      .subscribe((response) => {
        this.updateDriveData(response.drive, response.body);
      });
  }

  private fetchDriveData(drive: 'not-shared' | 'shared') {
    const url = `spreadsheet/${drive}`;
    return this.apiService.select(url).pipe(
      switchMap((response) => {
        return of({ drive, body: response.body });
      }),
    );
  }

  private updateDriveData(drive: 'not-shared' | 'shared', body: any) {
    if (drive === 'not-shared') {
      this.publicComponent.history = [];
      this.publicDriveData = body;
    }
    if (drive === 'shared') {
      this.sharedComponent.history = [];
      this.sharedDriveData = body;
    }
  }

  getGoogleDriveRootData(drive: 'not-shared' | 'shared') {
    this.loading = true;
    this.driveRequest$.next({ drive });
  }

  onTabChange(event: any) {
    const driveType = event.index === 0 ? 'not-shared' : 'shared';
    this.getGoogleDriveRootData(driveType);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirmSelectSheets(event: any) {
    const result = event;
    this.dialogRef.close(result);
  }

  onLoaderChange(loaderState: boolean) {
    this.loading = loaderState;
  }
}
