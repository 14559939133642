import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile',
  standalone: true,
  imports: [],
  template: `
    <article id="mobile">
      <h1><img src="assets/icon/logo_black.svg" alt="Logo" /></h1>
      <p>
        모바일 환경에서는 사용 불가해요. <br />
        PC에서 접속 부탁드려요
      </p>
    </article>
  `,
  styles: `
  @import '../../app.component.less';
  #mobile {
    .flex-column (24px);
    height: 80vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 > img {
      max-width: 200px;
    }
    p {
      .body-typo (2rem, 1.6);
    }
  }
  `,
})
export class MobileComponent {}
