import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class GenericService {
  currentPath: string = '';
  beta: boolean = false;
  constructor(public router: Router) {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => (this.currentPath = this.extractPath(event.urlAfterRedirects)));
  }

  return = () => {
    return false;
  };

  isMobile(): boolean {
    return window.matchMedia('(max-width: 479px)').matches;
  }

  pushToDataLayer(event: string, data: Record<string, any>): void {
    dataLayer.push({ event, ...data });
  }

  delay = (time: number): Promise<void> => new Promise<void>((resolve: () => void) => setTimeout(resolve, time));

  private extractPath(url: string): string {
    const segments = url.split('/').filter((segment) => segment);
    return segments.length > 0 ? segments[0] : '';
  }
}
