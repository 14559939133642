import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { fadeInOutTrigger } from '../../../animations';

import { ConnectedManagerAccount } from '../../../models/accounts.model';

import { NaverAccountService } from '../../../services/naver-account.service';
import { UserService } from '../../../../core/services/user.service';
import { GenericService } from '../../../services/generic.service';

@Component({
  selector: 'app-connected-naver-dialog',
  templateUrl: './connected-naver-dialog.component.html',
  styleUrl: './connected-naver-dialog.component.less',
  animations: [fadeInOutTrigger('imageFade', '0.3s ease'), fadeInOutTrigger('errorFade', '0.2s ease')],
})
export class ConnectedNaverDialogComponent {
  @ViewChild('idInput') idInput!: ElementRef;
  focusedInput: FocusedInput = FocusedInput.CUSTOMER_ID;
  accessGuide: 'login' | 'copy' = 'login';
  connectedManagerAccount!: ConnectedManagerAccount;
  isFormValid: boolean = false;
  isConnected: boolean | null = null;

  constructor(
    private userService: UserService,
    private naverAccountService: NaverAccountService,
    private genericService: GenericService,
    private renderer: Renderer2,
    private dialogRef: MatDialogRef<ConnectedNaverDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ConnectedManagerAccount,
  ) {
    this.connectedManagerAccount = this.data;
  }

  ngOnInit(): void {
    this.checkInputs();
  }
  ngAfterViewInit(): void {
    this.renderer.selectRootElement(this.idInput.nativeElement).focus();
  }

  checkInputs() {
    if (this.connectedManagerAccount)
      this.isFormValid =
        this.isValid(this.connectedManagerAccount.access_license) &&
        this.isValid(this.connectedManagerAccount.naver_customer_id) &&
        this.isValid(this.connectedManagerAccount.secret_key);
    else this.isFormValid = false;
  }

  isValid(value: string): boolean {
    return value.trim().length > 0;
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    this.naverAccountService.enrollNaverAccount(this.connectedManagerAccount).subscribe((response) => {
      this.isConnected = response.body;
      if (!this.isConnected) return;
      this.genericService.pushToDataLayer('connect_media', { login_id: this.userService.user?.login_id, media: 'naver sa' });
      this.dialogRef.close(true);
    });
  }

  get FocusedInput(): typeof FocusedInput {
    return FocusedInput;
  }
}
enum FocusedInput {
  CUSTOMER_ID = 1,
  ACCESS = 2,
}
