import { trigger, style, transition, animate, animation, useAnimation } from '@angular/animations';

// Define reusable animation
export const fadeInOutAnimation = animation([style({ opacity: '{{ startOpacity }}' }), animate('{{ duration }}', style({ opacity: '{{ endOpacity }}' }))], {
  params: { duration: '1s', startOpacity: 0, endOpacity: 1 },
});

// Define animation trigger function
export function fadeInOutTrigger(name: string, duration: string) {
  return trigger(name, [
    transition(':enter', [useAnimation(fadeInOutAnimation, { params: { startOpacity: 0, endOpacity: 1, duration: duration } })]),
    transition(':leave', [useAnimation(fadeInOutAnimation, { params: { startOpacity: 1, endOpacity: 0, duration: duration } })]),
  ]);
}
