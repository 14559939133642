import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { fadeInOutTrigger } from '../../../animations';

import { RawData, TemplateItem, _mockRawData } from '../../../models/raw-data.model';

import { RawDataService } from '../../../services/raw-data.service';
import { UserService } from '../../../../core/services/user.service';
import { GenericService } from '../../../services/generic.service';
import { NaverAccountService } from '../../../services/naver-account.service';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrl: './report-dialog.component.less',
  animations: [fadeInOutTrigger('hover', '0.3s ease-in-out')],
})
export class ReportDialogComponent {
  rawData!: any;
  type!: 'preview' | 'add';
  hover: boolean | null = null;
  selectedTemplates: number[] = [];
  hoverIndex: number | null = null;
  templateData: TemplateItem[] = [];
  useTemplate: number[] = [];
  myData: boolean = false;

  constructor(
    public userService: UserService,
    public rawDataService: RawDataService,
    public genericService: GenericService,
    private naverAccountService: NaverAccountService,
    private dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { rawData: RawData | null; mockRawData: _mockRawData | null; type: 'preview' | 'add'; useTemplates: number[]; myData?: boolean },
  ) {
    this.rawData = this.data.mockRawData ? this.data.mockRawData : this.data.rawData;
    this.templateData = this.data.mockRawData ? this.data.mockRawData._templates : this.data.rawData?._templates!;
    this.type = this.data.type;
    this.useTemplate = this.data.useTemplates ? this.data.useTemplates : [];
    this.myData = this.data.myData ?? false;
  }

  onConfirm(message: string) {
    const result = {
      message: message,
      templates: message === 'useTemplate' ? this.selectedTemplates : [],
    };
    this.dialogRef.close(result);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onCheckboxChange(id: number, isChecked: boolean) {
    if (isChecked) {
      this.selectedTemplates.push(id);
      return;
    }
    this.selectedTemplates = this.selectedTemplates.filter((k) => k !== id);
  }

  onRouterPreview(template: TemplateItem) {
    this.genericService.pushToDataLayer('preview_report', {
      login_id: this.userService.user?.login_id,
      media_account_id: this.naverAccountService.selectAccount.naver_login_id,
      media: 'naver sa',
      report_name: template.korean_name,
      page_name: this.genericService.currentPath,
    });
    window.open(`https://docs.google.com/spreadsheets/d/${template.spreadsheet_id}/edit?gid=${template.sheetfile_gid}`);
  }
}
