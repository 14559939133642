import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { fadeInOutTrigger } from '../../../../animations';

import { GoogleDriveData, GoogleDriveItem } from '../../../../models/google-drive.model';

import { ApiService } from '../../../../../core/services/api.service';

@Component({
  selector: 'app-drive-tab-content',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './drive-tab-content.component.html',
  styleUrl: './drive-tab-content.component.less',
  animations: [fadeInOutTrigger('loader', '0.3s ease'), fadeInOutTrigger('textFade', '0.2s ease')],
})
export class DriveTabContentComponent {
  @Input() tabType: 'not-shared' | 'shared' = 'not-shared';
  @Input() type!: 'sheets' | 'directory';
  @Input() driveData!: GoogleDriveData | null;
  @Input() loader!: boolean;
  @Output() rootClick = new EventEmitter();
  @Output() loaderChange = new EventEmitter<boolean>();
  @Output() onConfirmClick = new EventEmitter<any>();
  @Output() onCancelClick = new EventEmitter();
  history: GoogleDriveItem[] = [];
  selectedFile: GoogleDriveItem | null = null;
  selectedFolder: GoogleDriveItem | null = null;

  searchContent: string = '';
  contentEmpty: boolean = false;

  constructor(private apiService: ApiService) {}

  getSubDirectory(target: GoogleDriveItem) {
    this.loaderChange.emit(true);
    if (this.type === 'directory') this.selectedFile = null;

    const url = 'spreadsheet/subdirectory';
    this.apiService.select(url, { folder_id: target.id }).subscribe((response) => {
      this.driveData = response.body;
      this.updateHistory(target, this.driveData?.folders);

      this.loaderChange.emit(false);
    });
  }

  addToHistory(target: GoogleDriveItem) {
    const exists = this.history.some((item) => item.id === target.id);
    !exists && this.history.push(target);
  }

  updateHistory(target: GoogleDriveItem, newData?: any) {
    this.history = this.history.filter((item) => !newData.some((newItem: GoogleDriveItem) => newItem.id === item.id));
    const exists = this.history.some((item) => item.id === target.id);
    !exists && this.history.push(target);
  }

  onClickRootDirectory() {
    this.resetOptions();
    this.loaderChange.emit(true);
    this.rootClick.emit();
    this.contentEmpty = false;
  }

  resetOptions() {
    this.driveData = null;
    this.selectedFolder = null;
    this.selectedFile = null;
    this.history = [];
  }

  onConfirm() {
    const result = {
      selectedFile: this.selectedFile,
      selectedFolder: this.selectedFolder,
    };
    this.onConfirmClick.emit(result);
  }

  onCancel() {
    this.onCancelClick.emit();
  }

  searchDirectory() {
    if (!this.searchContent) {
      this.onClickRootDirectory();
    } else {
      this.loaderChange.emit(true);
      const url = `spreadsheet/${this.tabType}/search/${this.searchContent}`;
      this.apiService.select(url).subscribe((response) => {
        this.driveData = response.body;
        Object.values(this.driveData!).every((value) => value.length === 0) ? (this.contentEmpty = true) : (this.contentEmpty = false);
        this.loaderChange.emit(false);
      });
    }
  }
}
