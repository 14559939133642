import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { User } from '../models/user.model';
import { ManagerAccounts, Platform } from '../../shared/models/accounts.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userKey = '_us';
  user: User | null = null;
  managerAccounts: ManagerAccounts | null = null;
  constructor(private apiService: ApiService) {
    this.user = this.user ?? JSON.parse(sessionStorage.getItem(this.userKey)!);
  }

  setUser(user: User) {
    this.user = user;
    sessionStorage.setItem(this.userKey, JSON.stringify(user));
    sessionStorage.setItem(this.apiService.accessTokenKey, this.user.access_token);
    sessionStorage.setItem(this.apiService.refreshTokenKey, this.user.service_refresh_token);
  }

  getUser(callback?: () => void) {
    const url = 'users';
    this.apiService.select(url).subscribe((response) => {
      this.setUser(response.body);
      callback && callback();
    });
  }

  getManagerAccounts() {
    const url = 'users/manager-accounts';
    this.apiService.select(url).subscribe((response) => {
      this.managerAccounts = response.body;
    });
  }

  getConnectedManagerAccount(platform: Platform) {
    const url = 'users/manager-account';
    return this.apiService.select(url, { platform: platform });
  }
}
