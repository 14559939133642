import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { NaverAccount } from '../../models/accounts.model';

import { NaverAccountService } from '../../services/naver-account.service';

@Component({
  selector: 'app-account-dropdown',
  standalone: true,
  imports: [CommonModule, MatMenuModule],
  template: `
    @if (naverAccountService.AdvertisingAccounts.length > 1) {
      <article id="account-dropdown">
        <!-- @if (naverAccountService.AdvertisingAccounts.length > 1) { -->
        <section [matMenuTriggerFor]="accountDropdownOption" #trigger="matMenuTrigger" class="account-dropdown-select" [ngClass]="{ open: trigger.menuOpen }">
          <div class="account-dropdown-select-wrap">{{ naverAccountService.selectAccount.naver_login_id }}</div>
          <img src="assets/icon/arrow_dropdown.svg" alt="dropdown arrow" class="platform-dropdown-select-arrow" />
        </section>
        <mat-menu #accountDropdownOption="matMenu" class="account-dropdown-option-wrap" style="margin-top:4px;">
          @for (account of naverAccountService.AdvertisingAccounts; track $index) {
            <div mat-menu-item (click)="changeAccount(account)">{{ account.naver_login_id }}</div>
          }
        </mat-menu>
        <!-- } @else {
          <section class="account-dropdown-select">
            <div class="account-dropdown-select-wrap">{{ naverAccountService.selectAccount.naver_login_id }}</div>
          </section>
        } -->
      </article>
    }
  `,
  styles: `
    @import '../../../app.component.less';
    #account-dropdown {
      .account-dropdown-select {
        .flex-row (@justify: space-between);
        .button (@width: 200px, @height: 40px, @border: 1px solid @gray300);
        padding: 10px 12px;
      }
    }
    ::ng-deep {
      .mat-mdc-menu-panel.account-dropdown-option-wrap {
        max-height: 312px;
        width: 200px;
      }
    }
  `,
})
export class AccountDropdownComponent {
  @Output() selectAccount = new EventEmitter();
  constructor(protected naverAccountService: NaverAccountService) {}
  ngOnInit(): void {}

  changeAccount(account: NaverAccount) {
    this.naverAccountService.selectAccount = account;
    sessionStorage.setItem(this.naverAccountService.naverSelectAccountKey, JSON.stringify(account));
    this.selectAccount.emit();
  }
}
