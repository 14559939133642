import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from '../../core/services/api.service';
import { GoogleAuthService } from '../../core/services/google-auth.service';
import { GenericService } from '../../shared/services/generic.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    public googleAuthService: GoogleAuthService,
    public genericService: GenericService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const serviceId = params['service_id'] ?? localStorage.getItem(this.apiService.serviceIdKey);
      serviceId && this.googleAuthService.handleAuthCallback(serviceId);
    });
  }

  signInWithGoogle() {
    this.googleAuthService.signIn();
  }
}
