import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { RawDataService } from '../../shared/services/raw-data.service';
import { NaverAccountService } from '../../shared/services/naver-account.service';
import { UserService } from '../../core/services/user.service';

import { PlatformDropdownComponent } from '../../shared/components/platform-dropdown/platform-dropdown.component';
import { AccountDropdownComponent } from '../../shared/components/account-dropdown/account-dropdown.component';
import { RawDataTemplateComponent } from '../../shared/components/raw-data-template/raw-data-template.component';
import { MyRawData, RawDataFormatType } from '../../shared/models/raw-data.model';

@Component({
  selector: 'app-add-data',
  standalone: true,
  imports: [CommonModule, RouterModule, PlatformDropdownComponent, AccountDropdownComponent, RawDataTemplateComponent],
  templateUrl: './add-data.component.html',
  styleUrl: './add-data.component.less',
})
export class AddDataComponent {
  activeRawData: RawDataFormatType[] = [];
  constructor(
    public rawDataService: RawDataService,
    private naverAccountService: NaverAccountService,
    private userService: UserService,
  ) {
    this.activeRawData = history.state.activeRawData;
  }

  ngOnInit(): void {
    this.userService.user!.has_naver_manager_account && this.naverAccountService.getNaverAdvertisingAccounts().subscribe();
  }

  getRawData() {
    this.rawDataService.getRawData().subscribe((response) => {
      const body = response.body as MyRawData[];
      this.activeRawData = body.map((item) => item.raw_data.type);
    });
  }
}
