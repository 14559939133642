import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [],
  template: `<div [innerHTML]="htmlContent"></div>`,
  styles: ``,
})
export class PrivacyPolicyComponent {
  htmlContent: SafeHtml | null = null;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    const htmlFileUrl = 'https://s3.ap-northeast-2.amazonaws.com/imreport.io/public/saladlab/privacy_policy.html';
    this.http.get(htmlFileUrl, { responseType: 'text' }).subscribe((html) => (this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(html)));
  }
}
