<article id="im-report-accounts" class="setting-article">
  <section class="im-report-header">
    <h1 class="im-report-header-title"><img onclick="history.back()" src="assets/icon/back.svg" alt="back arrow" />계정연결</h1>
    @if (userService.user?.has_naver_raw_data) {
      <button routerLink="/my-data" class="im-report-header-route">나의 리포트 목록</button>
    }
  </section>
  @if (userService.user) {
    <section class="im-report-account-wrap" style="padding-bottom: 60px">
      <h2 class="im-report-account-title">나의 계정</h2>
      <div class="im-report-account-box">
        <h3 class="im-report-account-user">
          <img src="assets/icon/google.png" alt="google icon" />
          <div class="im-report-account-user-info">
            <p class="im-report-account-user-name">{{ userService.user.username }}</p>
            <p class="im-report-account-user-id">{{ userService.user.login_id }}</p>
          </div>
        </h3>
        <button (click)="googleAuthService.signOut()" class="im-report-account-user-logout">로그아웃</button>
      </div>
    </section>

    <section class="im-report-account-wrap">
      <h2 class="im-report-account-title">
        <div>
          <p>광고 계정 관리</p>
          <p class="im-report-account-title-sub">광고 데이터를 불러올 매체 계정을 관리하세요.</p>
        </div>
        <!-- <button (click)="routerAddData()" class="im-report-account-title-route">
          <span>데이터 연동하러 가기</span><img src="assets/icon/arrow_right.svg" alt="right arrow" />
        </button> -->
      </h2>
      <div class="im-report-account-box">
        <h3 class="im-report-account-user">
          <img src="assets/icon/naver.png" alt="google icon" />
          <div class="im-report-account-user-info">
            @if (userService.user.has_naver_manager_account) {
              <p class="im-report-account-user-name">{{ userService.managerAccounts?.naver_nickname }}</p>
              <p class="im-report-account-user-id">{{ userService.managerAccounts?.naver_login_id }}{{ '@naver.com' }}</p>
            } @else {
              <p class="im-report-account-user-unconnected">네이버 검색광고 관리 계정을 연결해 주세요.</p>
            }
          </div>
        </h3>
        <button (click)="openDialogNaverAccountManagement()" class="im-report-account-user-connected">
          {{ userService.user.has_naver_manager_account ? '관리' : '계정 연결' }}
        </button>
      </div>
      <div class="im-report-account-box disabled">
        <h3 class="im-report-account-coming">
          <img src="assets/icon/kakao.png" alt="google icon" />
          <div class="im-report-account-coming-info"><span class="im-report-account-coming-label">준비 중</span>아직 지원하지 않는 서비스예요.</div>
        </h3>
        <button class="im-report-account-coming-button">준비 중</button>
      </div>
      <div class="im-report-account-box disabled">
        <h3 class="im-report-account-coming">
          <img src="assets/icon/meta.png" alt="google icon" />
          <div class="im-report-account-coming-info"><span class="im-report-account-coming-label">준비 중</span>아직 지원하지 않는 서비스예요.</div>
        </h3>
        <button class="im-report-account-coming-button">준비 중</button>
      </div>
      <div class="im-report-account-box disabled">
        <h3 class="im-report-account-coming">
          <img src="assets/icon/tiktok.png" alt="google icon" />
          <div class="im-report-account-coming-info"><span class="im-report-account-coming-label">준비 중</span>아직 지원하지 않는 서비스예요.</div>
        </h3>
        <button class="im-report-account-coming-button">준비 중</button>
      </div>
    </section>
  }
</article>
