import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date): string {
    const now = new Date();
    const date = new Date(value);
    const diff = now.getTime() - date.getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return minutes < 60 ? `${minutes}분 전` : hours < 24 ? `${hours}시간 전` : days < 2 ? `${days}일 전` : `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
  }
}
