import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ApiService } from '../services/api.service';

export const authGuard: CanActivateFn = () => {
  const apiService = inject(ApiService);
  const router = inject(Router);
  const accessToken = sessionStorage.getItem(apiService.accessTokenKey);
  if (!accessToken) {
    router.navigate(['/login']);
    return false;
  }
  return true;
};
